import React from "react"
import SEO from "../../components/layout/seo"

export default ({ data }) => {

  return (
    <div>
      <SEO title="404" />
      <div className="container-fluid my-5">
        <div className="row">
          <div className="col-12 text-center">
            <h2>Seite nicht gefunden</h2>
          </div>
          <div className="col-12 text-center">
            <p>Ups, wir konnten diese Seite nicht finden!</p>
          </div>
        </div>
      </div>
    </div>
  )
}
